import { useState, useEffect } from 'react';
import _debounce from 'lodash/debounce';

// helpers
import { computeDeviceSize, ComputedDevice } from 'src/lib/utils';

interface Props {
  debounce?: number;
}

const useDeviceSize = ({ debounce = 100 }: Props = {}): ComputedDevice => {
  const [state, setState] = useState(
    computeDeviceSize(typeof window !== 'undefined' && window.innerWidth),
  );

  useEffect(() => {
    const resizeHandler = _debounce((e) => {
      if (e?.currentTarget?.innerWidth) {
        setState(computeDeviceSize(e.currentTarget.innerWidth));
      }
    }, debounce);

    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [debounce, state]);

  return state;
};

export default useDeviceSize;
