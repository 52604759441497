import { Sys } from 'contentful';
import _get from 'lodash/get';
import { format } from 'fecha';
import startCase from 'lodash/startCase';
import kebabCase from 'lodash/kebabCase';
import camelCase from 'lodash/camelCase';
import _cloneDeep from 'lodash/cloneDeep';

import {
  appendSuccessParam,
  appendTrailingSlash,
  fetchLocalizedEquivalentPage,
  formatUrl,
  getCurrentPage,
  getLocalizedFallbackUrl,
  getUrlDomain,
  hasLocale,
  isAbsolutePlaidUrl,
  isAnchor,
  isLegacyLocaleLink,
  isPdfUrl,
  isStaticAsset,
  mapSearchParamsToObject,
  mutateHardCodedLinks,
  prependLeadingSlash,
  prependLocale,
  prependStagingHash,
  replaceHrefValueWithLocale,
  sanitizeVideoUrl,
  stripLeadingSlash,
} from './url-helpers';
import { blogCmsClient } from 'src/lib/contentful-client';
import {
  fetchLinkToken,
  fetchMetrics,
  getLinkDemoHost,
  getLinkTokenEndpoint,
} from 'src/lib/link-demo-utils';

// constants
import {
  CmsComponents,
  Contentful,
  ContentfulLocales,
  LocaleMap,
  Locales,
} from 'src/lib/constants';

export const truncateString = (str, maxLength) => {
  const strArray = str.split(' ');
  if (strArray.length <= maxLength) {
    return str;
  }
  return `${str.split(' ').splice(0, maxLength).join(' ')}...`;
};

interface PagePathOpts {
  total: number;
  max: number;
  options?: Record<string, unknown>;
}

interface PagePathObject {
  params: {
    page: string;
    [key: string]: string;
  };
}

export const generatePagePaths = ({
  total,
  max,
  options,
}: PagePathOpts): Array<PagePathObject> => {
  const pageNums = Math.ceil(total / max);
  const paths = [];
  for (let i = 2; i <= pageNums; i++) {
    paths.push({ params: { page: `${i}`, ...options } });
  }
  return paths;
};

export const arrayToCommaSeparatedString = (arr) => {
  return arr
    ?.join(', ')
    .replace(/,(?!.*,)/gim, `${arr.length > 2 ? ',' : ''} &`);
};

const fetchEntries = async ({ contentType, offset = 0, limit = 100 }) => {
  try {
    const entries = await blogCmsClient.getEntries({
      content_type: contentType, // eslint-disable-line
      skip: offset,
      limit,
    });
    return entries;
  } catch (e) {
    throw e;
  }
};

interface FetchAllEntriesArgs {
  contentType: string;
}

interface Entries {
  fields?: Record<string, number>;
  sys?: Sys;
}

export const fetchAllEntries = async ({
  contentType,
}: FetchAllEntriesArgs): Promise<Array<Entries>> => {
  let offset = 0;
  const entries = [];
  const firstBatch = await fetchEntries({
    contentType,
    offset,
    limit: 100,
  });
  let { total: count } = firstBatch;
  const { items: firstPage } = firstBatch;
  entries.push(firstPage);

  while (count > 0) {
    const nextBatch = await fetchEntries({
      contentType,
      offset,
      limit: 100,
    });
    const { items: nextPage } = nextBatch;
    entries.push(nextPage);
    count -= nextPage.length;
    offset += nextPage.length;
  }

  return entries.flat();
};

interface FetchEntryId {
  contentType: string;
  param: string;
  query: unknown;
}

export const fetchEntryId = async ({
  contentType,
  param,
  query,
}: FetchEntryId): Promise<string> => {
  const res = await blogCmsClient.getEntries({
    content_type: contentType, // eslint-disable-line
    [param]: query,
  });
  const {
    items: [matchingEntry],
  } = res;
  const {
    sys: { id },
  } = matchingEntry;
  return id;
};

export const addHoursToDate = (date: string): string => {
  const newDate = new Date(date);
  newDate.setHours(newDate.getHours() + 4);
  return newDate.toJSON();
};

export const toKebabCase = (str: string): string => {
  if (!str) {
    return null;
  }
  return kebabCase(str);
};

export const unKebab = (str: string): string => {
  if (!str) {
    return null;
  }
  return startCase(str);
};

export const convertToSelectOptions = ({ items = [] }) => {
  return items.map((item) => {
    return { label: item, value: toKebabCase(item) };
  });
};

export const generateBlogExcerptProps = async (
  entry,
  excerptInstance,
): Promise<Record<string, unknown>> => {
  if (!excerptInstance) {
    return entry.fields?.components;
  }

  const slug = excerptInstance.fields?.slug;
  const blogExcerpt = await blogCmsClient.getEntries({
    content_type: 'blogPostTemplate',
    'fields.pageSlug[in]': slug,
    limit: 1,
    include: 10,
  });
  const blogEntry = blogExcerpt.items[0];
  return entry.fields?.components.map((component) => {
    if (
      component.sys?.contentType?.sys?.id === CmsComponents.BLOG_POST_EXCERPT
    ) {
      return {
        ...component,
        fields: {
          ...component.fields,
          metaTitle: blogEntry.fields['metaTitle'],
          excerpt: blogEntry.fields['excerpt'],
        },
      };
    }
    return component;
  });
};

export const formatTime = (time: Date): string => {
  return format(time, 'h:mm');
};

export const getTimePeriod = (time: Date): string => {
  return Number(format(time, 'H')) < 12 ? 'AM' : 'PM';
};

export const removeTimezoneOffset = (isoDateTime: string): string => {
  // Contentful datetime string with timezone offset is ISO 8601 format
  // The timezone offset is always the last 6 digits in string
  return isoDateTime.slice(0, -6);
};

export interface ComputedDevice {
  isSmall: boolean;
  isMedium: boolean;
  isLarge: boolean;
  isMainNav: boolean;
  isXLarge: boolean;
  isXXLarge: boolean;
  isXXXLarge: boolean;
  width: number;
}

export const computeDeviceSize = (width: number): ComputedDevice => {
  return {
    isSmall: width <= 639,
    isMedium: width >= 640,
    isLarge: width >= 1024,
    isMainNav: width >= 1280,
    isXLarge: width >= 1280,
    isXXLarge: width >= 1440,
    isXXXLarge: width >= 1680,
    width,
  };
};

export const computeSpacerHeight = (height, { device }) => {
  const calculatedHeight =
    device.isLarge ||
    device.isMainNav ||
    device.isXLarge ||
    device.isXXLarge ||
    device.isXXXLarge
      ? height
      : device.isMedium
      ? Math.ceil(height * 0.75)
      : Math.ceil(height * 0.5);

  return calculatedHeight;
};

// Used to determine the existence of a component in an array of page components from Contentful
export const findComponent = (components = [], componentName = '') => {
  try {
    return components.find((component) => {
      return _get(component, Contentful.CONTENT_TYPE_ID) === componentName;
    });
  } catch (e) {
    console.log(e); // eslint-disable-line
  }
};

export const mapISOToFauxLocale = (locale: string): string => {
  return (
    Object.entries(LocaleMap).find(([, v]) => {
      return v === locale;
    })?.[0] || 'not_valid'
  );
};

export const mapISOToContentfulLocale = (locale: string): string => {
  const normalizedLocale =
    locale.slice(0, 2) + '-' + locale.slice(3).toUpperCase();

  if (ContentfulLocales.has(normalizedLocale)) {
    return normalizedLocale;
  }

  return 'not-valid';
};

// gets the locale from one of many possible sources
export const getLocale = (props): string => {
  const locale =
    LocaleMap[props?.fields?.locale?.toLowerCase()] || // legacy "faux" locale
    props?.params?.id || // hard coded locale from static generation
    props?.sys?.locale || // locale provided by CMS
    Locales.EN_US; // fallback to en-us just in case
  return locale.toLowerCase();
};

// constructs an associative array of pages to be generated by next.js static generation
export const toLocalizedPageParams = (accumulator, entity) => {
  if (entity?.fields?.pageSlug && entity?.fields?.locale) {
    accumulator.push({
      params: {
        id: LocaleMap[entity?.fields?.locale.toLowerCase()],
        index: entity?.fields?.pageSlug,
      },
    });
  }
  return accumulator;
};

export const generateBreadcrumbStructuredData = ({
  pathname,
}: {
  pathname: string;
}): {
  '@context': string;
  '@type': string;
  itemListElement: {
    '@type': string;
    position: number;
    name: string;
    item: string;
  }[];
} => {
  const pathList = pathname.substring(1).split('/');
  const itemListElement = [...Array(pathList.length)].map((x, i) => {
    const Name = {
      ios: 'iOS',
      api: 'API',
      ach: 'ACH',
      oauth: 'OAuth',
      vopay: 'VoPay',
      achq: 'ACHQ',
      drivewealth: 'DriveWealth',
      tabapay: 'TabaPay',
      kyc: 'KYC',
      aml: 'AML',
      sdk: 'SDK',
      sdks: 'SDKs',
      ui: 'UI',
      ux: 'UX',
      default: startCase(camelCase(pathList[i])),
    };

    return {
      '@type': 'ListItem',
      position: i + 1,
      name: Name[pathList[i]] || Name.default,
      item: `https://plaid.com/${pathList.slice(0, i + 1).join('/')}`,
    };
  });
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement,
  };
};

export const generateArticleStructuredData = ({
  authorName,
  datePublished,
  headline,
  image,
}: {
  authorName: string;
  datePublished: string;
  headline: string;
  image: string;
}): {
  '@context': string;
  '@type': string;
  author: {
    '@type': string;
    name: string;
  };
  datePublished: string;
  headline: string;
  image: string;
  publisher: {
    '@type': string;
    name: string;
    logo: {
      '@type': string;
      url: string;
    };
  };
} => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Article',
    author: {
      '@type': 'Person',
      name: authorName,
    },
    datePublished,
    headline,
    image,
    publisher: {
      '@type': 'Organization',
      name: 'Plaid',
      logo: {
        '@type': 'ImageObject',
        url:
          'https://dka575ofm4ao0.cloudfront.net/pages-transactional_logos/retina/93189/plaid-logo-horizontal-RGB-2.png',
      },
    },
  };
};

export const generateEventStructuredData = ({
  name,
  description,
  startDate,
  eventStatus = 'Scheduled',
  eventAttendanceMode = 'Online',
  streetAddress = '',
  addressLocality = '',
  addressRegion = '',
  postalCode = '',
  addressCountry = '',
  url,
}: {
  name: string;
  description;
  startDate: string;
  eventStatus?: string;
  eventAttendanceMode?: string;
  streetAddress?: string;
  addressLocality?: string;
  addressRegion?: string;
  postalCode?: string;
  addressCountry?: string;
  url: string;
}): {
  '@context': string;
  '@type': string;
  name: string;
  description: string;
  startDate: string;
  eventStatus: string;
  eventAttendanceMode: string;
  location: {
    '@type': string;
    address?: {
      '@type': string;
      streetAddress: string;
      addressLocality: string;
      addressRegion: string;
      postalCode: string;
      addressCountry: string;
    };
    url: string;
  };
  organizer: {
    '@type': string;
    name: string;
    url: string;
  };
} => {
  const hasAddress =
    (eventAttendanceMode === 'Mixed' || 'Offline') &&
    streetAddress &&
    addressLocality &&
    addressRegion &&
    postalCode &&
    addressCountry;

  return {
    '@context': 'https://schema.org',
    '@type': 'Event',
    name,
    description,
    startDate,
    eventStatus: `https://schema.org/Event${eventStatus}`,
    eventAttendanceMode: `https://schema.org/${eventAttendanceMode}EventAttendanceMode`,
    location: {
      '@type': hasAddress ? 'Place' : 'VirtualLocation',
      ...(hasAddress && {
        address: {
          '@type': 'PostalAddress',
          streetAddress,
          addressLocality,
          addressRegion,
          postalCode,
          addressCountry,
        },
      }),
      url,
    },
    organizer: {
      '@type': 'Organization',
      name: 'Plaid',
      url: 'https://plaid.com/',
    },
  };
};

export const findEntity = (arr, entity) => {
  return arr.find((x) => {
    return x.value === entity;
  });
};

export const getRequiredMessage = (fieldName, lang = 'en') => {
  const requiredMessages = {
    en: `The ${fieldName} field is empty; it is a required field and must be filled in.`,
    es: `El campo ${fieldName} está vacío; es un campo obligatorio y debe ser llenado.`, // Google Translate based on en version
    fr: `Le champ ${fieldName} est vide; c'est un champ obligatoire et doit être rempli.`, // Google Translate based on en version
    nl: `Het veld ${fieldName} is leeg; het is een verplicht veld en moet worden ingevuld.`, // Google Translate based on en version
  };
  return requiredMessages[lang];
};

const strStartsWithVowel = (str: string): string => {
  const vowels = ['a', 'e', 'i', 'o', 'u'];
  const startsWithVowel = vowels.some((vowel) => {
    return str.startsWith(vowel);
  });
  if (startsWithVowel) {
    return 'an';
  }
  return 'a';
};

export const replace = ({
  toModify,
  pattern,
  replacement,
}: {
  toModify: Array<string> | string;
  pattern: any;
  replacement: string | ((m: string, p1: string, p2: string) => string);
}): Array<string> | string => {
  const replacer = (str) => {
    return str.replace(pattern, replacement);
  };
  return Array.isArray(toModify)
    ? // handle arrays
      toModify.map((item) => {
        return replacer(item);
      })
    : // handle plain string
      replacer(toModify);
};

/**
 * @description Use hard-coded mapping to filter forms that require a unique trackingId
 * @param {number} marketoId
 * @param {string} internalTitle
 * @returns {string}
 * */
export const getTrackingId = (marketoId, internalTitle) => {
  return [1449].includes(marketoId)
    ? internalTitle.replace(/ /g, '_').toUpperCase()
    : 'MARKETO_FORM';
};

export const assetIsVideo = (asset) => {
  return /video\/(mp4|webm|ogg|quicktime|x-msvideo|mpeg|mpg|mpe|3gpp|x-ms-wmv|avi|divx|flv|x-flv|x-m4v|x-matroska|x-mng|x-ms-asf|x-sgi-movie)/.test(
    asset?.fields?.file?.contentType,
  );
};

export const assetIsImage = (asset) => {
  return /image\/(apng|bmp|gif|jpeg|png|svg\+xml|tiff|webp)/.test(
    asset?.fields?.file?.contentType,
  );
};

export const assetIsMaybeLottie = (asset) => {
  return asset?.fields?.file?.contentType === 'application/json';
};

// we want to consistently move quicktime files to the top of an array
export const byVideoType = (a, b) => {
  if (a?.fields?.file?.contentType === 'video/quicktime') {
    return -1;
  } else if (b?.fields?.file?.contentType === 'video/quicktime') {
    return 1;
  } else {
    return 0;
  }
};

export const getColumn = (
  items: Array<unknown>,
  index: number,
): Record<string, string | number> => {
  if (items.length === 3) {
    return { lgOffset: index === 0 ? 2 : 1, lg: 6, sm: 8, xs: 24 };
  }
  if (items.length === 2) {
    return {
      lgOffset: 2,
      lg: 9,
      smOffset: index === 1 ? 2 : 0,
      sm: 11,
      xs: 24,
    };
  }
  return {
    xs: 20,
    xsOffset: 2,
  };
};

export const isRichTextNodeImage = (item) => {
  return (
    item?.nodeType === 'embedded-asset-block' &&
    item?.data?.target?.fields?.file?.url
  );
};

export const isRichTextNodeVideo = (item) => {
  return (
    item?.nodeType === 'embedded-entry-block' &&
    item?.data?.target?.fields?.video?.length >= 0
  );
};

export const getHrefFromRichText = (content) => {
  if (!Array.isArray(content)) {
    return undefined;
  }
  return (
    content.filter((entry) => {
      return entry?.data?.target?.sys?.contentType?.sys?.id === 'button2';
    })[0]?.data?.target?.fields?.url || undefined
  );
};

export const removeHrefFromRichTextButtonContent = (content) => {
  if (!Array.isArray(content)) {
    return [];
  }
  const mutableContent = _cloneDeep(content); // must leave original href in place in order to set href on the container
  return mutableContent.map((entry) => {
    if (
      entry?.data?.target?.sys?.contentType?.sys?.id !== CmsComponents.BUTTON
    ) {
      return entry;
    } else {
      entry.data.target.fields.url = null;
      return entry;
    }
  });
};

// url helpers
export {
  appendSuccessParam,
  appendTrailingSlash,
  fetchLinkToken,
  fetchLocalizedEquivalentPage,
  fetchMetrics,
  formatUrl,
  getCurrentPage,
  getLinkDemoHost,
  getLinkTokenEndpoint,
  getLocalizedFallbackUrl,
  getUrlDomain,
  hasLocale,
  isAbsolutePlaidUrl,
  isAnchor,
  isLegacyLocaleLink,
  isPdfUrl,
  isStaticAsset,
  mapSearchParamsToObject,
  mutateHardCodedLinks,
  prependLeadingSlash,
  prependLocale,
  prependStagingHash,
  replaceHrefValueWithLocale,
  sanitizeVideoUrl,
  strStartsWithVowel,
  stripLeadingSlash,
};
