import Threads5Theme from 'threads5/themes/Threads5';
import createTheme from 'threads5/themes/create-theme';

const breakpoints = {
  xs: 0, // mobile
  sm: 640, // tablet
  md: 1024, // md screen
  lg: 1280, // lg screen
  xl: 1440, // xl screen
  xxl: 1730, // xxl screen
};

export const colors = {
  white: '#ffffff',
  White: '#ffffff',
  black100: '#fafafa',
  black200: '#f6f6f6',
  black300: '#ededed',
  black400: '#dcdcdc',
  black500: '#c5c5c5',
  black600: '#949494',
  black700: '#767676',
  black800: '#555555',
  black900: '#383838',
  black1000: '#111111',
  black: '#111111',
  Black: '#111111',
  gray: '#f6f6f6',
  Gray: '#f6f6f6',
  transparent: 'rgba(0, 0, 0, 0)',

  'dark-gray': 'rgb(75, 75, 75)',

  // Colorful colors
  blue200: '#d4f9ff',
  blue400: '#b1eefc',
  blue600: '#63daff',
  blue700: '#37b0f4',
  blue800: '#0a85ea',
  blue900: '#0072cf',
  blue1000: '#0061b3',

  gold: '#ba7d17',

  green200: '#d0fce4',
  green400: '#abffdb',
  green600: '#5befbd',
  green800: '#23d09c',
  green900: '#1fa077',
  green1000: '#1e8262',

  yellow200: '#fefbb8',
  yellow400: '#fbf1a0',
  yellow600: '#fce76b',
  yellow800: '#f2d211',
  yellow900: '#e6ba23',
  yellow1000: '#dba81b',

  red200: '#ffd7dc',
  red400: '#ffaab9',
  red600: '#ff7885',
  red800: '#f44e66',
  red900: '#d83d57',
  red1000: '#b72743',

  purple200: '#e1e1ff',
  purple400: '#c6befc',
  purple600: '#9986f7',
  purple800: '#7646ec',
  purple900: '#5c2ec7',
  purple1000: '#4726a3',
};

// Threads Fonts
export const fontFamily = {
  sans: 'Cern, Helvetica, Arial, sans-serif',
  mono: 'Inconsolata, Consolas, Courier, monospace',
};

const theme = createTheme({
  ...Threads5Theme,
  palette: {
    ...Threads5Theme.palette,
    ...colors,
  },
  ...breakpoints,
});

export default theme;
